import React, { useState, useEffect  } from "react";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineSend, AiOutlineReload } from "react-icons/ai"
import * as XLSX from 'xlsx';
import { initializeApp } from "firebase/app";
import { getFirestore, setDoc, doc, getDoc } from "firebase/firestore";


// TODO figure out refreh call from parent function

function Insert({getData}){

    const firebaseConfig = {
    apiKey: "AIzaSyDVhgpIqkEexS6B3slFq11Y59ojOOgqj7Q",
    authDomain: "web-trans-3971a.firebaseapp.com",
    projectId: "web-trans-3971a",
    storageBucket: "web-trans-3971a.appspot.com",
    messagingSenderId: "308026154470",
    appId: "1:308026154470:web:360585ee132965749d8a27",
    measurementId: "G-ZG8VB6D2SY"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const [numInserts, setNumInserts] = useState(0);
    const [numArray, setNumArray] = useState([]);
    const today = new Date();
    const year = today.getFullYear().toString().padStart(4, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;

    const [queried, setQueried] = useState(false);

    const [accountSourceArray, setAccountSourceArray]= useState([]);
    const [transDateArray, setTransDateArray]= useState([]);
    const [TransactionDescriptionArray, setTransactionDescriptioneArray]= useState([]);

    const [TransactionAmountArray, setTransactionAmountArray]= useState([]);
    const [AccountBalanceArray, setAccountBalanceArray]= useState([]);
    const [CommentsArray, setCommentsArray]= useState([]);

    const handlePlus = ()=>{
        setNumInserts(numInserts+1);
        setAccountSourceArray([...accountSourceArray, "sample acc source"]);

        setTransDateArray([...transDateArray, dateString]);

        setTransactionDescriptioneArray([...TransactionDescriptionArray, "sample description"]);

        setTransactionAmountArray([...TransactionAmountArray, 0]);

        setAccountBalanceArray([...AccountBalanceArray, 0]);

        setCommentsArray([...CommentsArray, "sample comment"]);

    }


    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const sendInsertRequest = async ()=>{

        const docSnap = await getDoc(doc(db, 'Next ID', 'next'));
        let ID = parseInt(docSnap.data().ID);
        console.log(ID);

        // // @TODO add filters

        for (let i = 0; i < accountSourceArray.length; i++) {
            const data = {
                TransactionId: ID,
                AccountSource: accountSourceArray[i] || '',
                transDate: transDateArray[i],
                TransactionDescription: TransactionDescriptionArray[i] || '',
                TransactionAmount: TransactionAmountArray[i],
                AccountBalance: AccountBalanceArray[i],
                Comments: CommentsArray[i] || '',
            }
            // Optimization can remove the await to have all inserts run in paralell but risks only some going through and the rest not going through
            await setDoc(doc(db, "Transactions", ID.toString()), data);
            ID += 1;
        }


        setQueried(true);
        const toInsert = {
            ID: ID.toString(),
        }
        setDoc(doc(db, 'Next ID', 'next'), toInsert);
        // const response = await fetch('./api', {
        //   method: 'POST',
        //   body: JSON.stringify({type: 'insert', accountSourceArray: accountSourceArray, transDateArray: transDateArray, TransactionDescriptionArray: TransactionDescriptionArray, 
        //   TransactionAmountArray: TransactionAmountArray, AccountBalanceArray: AccountBalanceArray, CommentsArray: CommentsArray}),
        //   headers: {
        //     'Content-Type': 'application/json',
        //   }
        // });
    }

    const refreshData = ()=>{
        setQueried(false);
        getData();

    }

    const handleFileChange = async (event) => {
        try {
            const file = event.target.files[0];
            const bufferArray = await file.arrayBuffer();
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            console.log(ws);
            let accSourArr = [];
            let transDtArr = [];
            let transDescArr = [];
            let transAmtArr = [];
            let accBalArr = [];
            let commArr = [];

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                accSourArr.push(element.AccountSource);
                // console.log(element.TransactionDate);
                transDtArr.push(formatDate(element.TransactionDate));
                transDescArr.push(element.TransactionDescription);
                transAmtArr.push(element.TransactionAmount);
                accBalArr.push(element.AccountBalance);
                commArr.push(element.Comments);
            }
            
            setAccountSourceArray(accountSourceArray.concat(accSourArr));
            setTransDateArray(transDateArray.concat(transDtArr));
            setTransactionDescriptioneArray(TransactionDescriptionArray.concat(transDescArr));
            setTransactionAmountArray(TransactionAmountArray.concat(transAmtArr));
            setAccountBalanceArray(AccountBalanceArray.concat(accBalArr));
            setCommentsArray(CommentsArray.concat(commArr));
            setNumInserts(numInserts+data.length);





        } catch (error) {
            console.error(error);
        }
        event.target.value = null;
    };

    const handleMinus = () =>{
        if (numInserts <=0) {
            alert('cant reduce anymore')
            return;
        }else{
            setNumInserts(numInserts-1);
            setAccountSourceArray(accountSourceArray.slice(0,-1));
            setTransDateArray(transDateArray.slice(0,-1));
            setTransactionDescriptioneArray(TransactionDescriptionArray.slice(0,-1));
            setTransactionAmountArray(TransactionAmountArray.slice(0,-1));
            setAccountBalanceArray(AccountBalanceArray.slice(0,-1));
            setCommentsArray(CommentsArray.slice(0,-1));

        }
    }

    const onDeleteInsert = (index) => {
        
        setAccountSourceArray(accountSourceArray.splice(index, 1));

        setTransDateArray(transDateArray.splice(index,1));

        setTransactionDescriptioneArray(TransactionDescriptionArray).splice(index,1);

        setTransactionAmountArray(TransactionAmountArray).splice(index,1);

        setAccountBalanceArray(AccountBalanceArray).splice(index,1);

        setCommentsArray(CommentsArray).splice(index,1);
        setNumInserts(numInserts-1).splice(index,1);
    }

    

    const handleASChange = (event, index) => {
        const newInputValues = [...accountSourceArray];
        newInputValues[index] = event.target.value;
        setAccountSourceArray(newInputValues);
      };

      const handleTDChange = (event, index) => {
        const newInputValues = [...transDateArray];
        newInputValues[index] = event.target.value;
        setTransDateArray(newInputValues);
      };

      const handleTDSChange = (event, index) => {
        const newInputValues = [...TransactionDescriptionArray];
        newInputValues[index] = event.target.value;
        setTransactionDescriptioneArray(newInputValues);
      };

      const handleTAChange = (event, index) => {
        const newInputValues = [...TransactionAmountArray];
        newInputValues[index] = event.target.value;
        setTransactionAmountArray(newInputValues);
      };

      const handleABChange = (event, index) => {
        const newInputValues = [...AccountBalanceArray];
        newInputValues[index] = event.target.value;
        setAccountBalanceArray(newInputValues);
      };

      const handleCChange = (event, index) => {
        const newInputValues = [...CommentsArray];
        newInputValues[index] = event.target.value;
        setCommentsArray(newInputValues);
      };

      

      


    const tableHeaderStyle = {
        backgroundColor: '#000000',
        borderBottom: '1px solid #ddd',
        fontWeight: 'bold',
        padding: '8px',
        textAlign: 'left',
    };
      
    const tableCellStyle = {
        borderBottom: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
    };

    useEffect(() => {
        const newArray = Array.from({ length: numInserts }, (_, index) => index + 1);
        setNumArray(newArray);
      }, [numInserts]);

    


    return (
        <div>
            <div className="separator"></div>
            <div style={{ display: "flex"}}>
                <AiOutlinePlus size={42} onClick={handlePlus}></AiOutlinePlus>
                <AiOutlineMinus size={42} onClick={handleMinus}></AiOutlineMinus>
                {queried?
                    <div>
                        <AiOutlineReload size={42} onClick={refreshData}/>
                    </div> 
                    :
                    <div></div>}
                
            </div>
            <div>
                Please upload a XLSX file ONLY and make sure it has all the required columns: <input type="file" onChange={(event) => handleFileChange(event)} />
            </div>
            <div style={{ overflowX: 'auto' }} className="table-container">
                <table style={{ borderCollapse: 'collapse', minWidth: '100%' }}>
                <thead style={{ position: 'sticky', top: 0 }} className="fixed-header">
                    <tr>
                    <th style={tableHeaderStyle}>AccountSource</th>
                    <th style={tableHeaderStyle}>TransactionDate</th>

                    <th style={tableHeaderStyle}>TransactionDescription</th>
                    <th style={tableHeaderStyle}>TransactionAmount</th>
                    <th style={tableHeaderStyle}>AccountBalance</th>

                    <th style={tableHeaderStyle}>Comments</th>                    
                    </tr>
                </thead>
                <tbody>
                    {numArray.map((item, index) => (
                    <tr key={index}>
                        <td style={tableCellStyle}><input value={accountSourceArray[index] || ""} type='text' onChange={event => handleASChange(event, index)}></input></td>
                        <td style={tableCellStyle}><input value={transDateArray[index] || ""} type='date' onChange={event => handleTDChange(event, index)}></input></td>

                        <td style={tableCellStyle}><input value={TransactionDescriptionArray[index] || ""} type='text' onChange={event => handleTDSChange(event, index)}></input></td>
                        <td style={tableCellStyle}><input value={TransactionAmountArray[index] || ""} type='number' onChange={event => handleTAChange(event, index)}></input></td>
                        <td style={tableCellStyle}><input value={AccountBalanceArray[index] || ""} type='number' onChange={event => handleABChange(event, index)}></input></td>

                        <td style={tableCellStyle}><input value={CommentsArray[index] || ""} type='text' onChange={event => handleCChange(event, index)}></input></td>                        
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
            <button onClick={sendInsertRequest}>Submit request <AiOutlineSend></AiOutlineSend></button>
        </div>
    )
}

export default Insert;