import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, getDocs, doc, deleteDoc } from "firebase/firestore";

function Filters({filteredData}) {
  const firebaseConfig = {
  apiKey: "AIzaSyDVhgpIqkEexS6B3slFq11Y59ojOOgqj7Q",
  authDomain: "web-trans-3971a.firebaseapp.com",
  projectId: "web-trans-3971a",
  storageBucket: "web-trans-3971a.appspot.com",
  messagingSenderId: "308026154470",
  appId: "1:308026154470:web:360585ee132965749d8a27",
  measurementId: "G-ZG8VB6D2SY"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const transColl = collection(db, 'Transactions');

  let startDate = null;
  let endDate = null;
  const [amountOption, setAmountOption] = useState("equal to");
  const [inputs, setInputs] = useState({amount: '', comment: '', accountSource: '', transDescription: ''});

  
  const amountRadioChange = (event)=>{
    setAmountOption(event.target.value)
    console.log(event.target.value);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  const applyFilters = async ()=>{
    console.log(inputs);
    let hasAccountSource = false;
    let hasStart = false;
    let hasAmount = false;
    let hasComment = false;
    let hasEnd = false;
    let hasTransDescription = false;

    if (inputs.transDescription !== '') {
      hasTransDescription = true;
    }
    if (inputs.amount!=='') {
      hasAmount = true;
    }

    if (inputs.comment!=='') {
      if (inputs.comment.length >= 3) {
        hasComment = true;
      }else{
        alert('comment must be more than 3 characters long');
        return;
      }
      
    }

    if (inputs.accountSource!=='') {
      if (inputs.accountSource.length >= 3) {
        hasAccountSource = true;
      }else{
        alert('Account source must be more than 3 characters long')
      }
       
    }

    if (startDate!==null) {
      hasStart = true;
    }else{
      startDate = new Date('01/01/1980');
    }

    if (endDate!==null) {
      hasEnd = true;
    }else{
      endDate = new Date('01-01-2100');
    }

    const q = query(collection(db, "Transactions"));
    const querySnapshot = await getDocs(q);
    let transactions = [];

    console.log(inputs);
    console.log(startDate);
    console.log(endDate);
    console.log('______________________________');


    if (amountOption === 'equal to') {
      querySnapshot.forEach((doc) => {
        let element = doc.data();
        console.log(element);
        console.log(hasAmount);
        const transDate = new Date(element.transDate);
        if ((element.TransactionAmount === inputs.amount || !hasAmount) && element.AccountSource.toLowerCase().includes(inputs.accountSource.toLowerCase())
            && element.TransactionDescription.toLowerCase().includes(inputs.transDescription.toLowerCase()) && element.Comments.toLowerCase().includes(inputs.comment.toLowerCase())
            && transDate >= startDate && transDate <= endDate) {
          // check if this works on the filter?

          const newElement = {
            TransactionId: element.TransactionId,
            AccountSource: element.AccountSource || '',
            transDate: element.transDate,
            TransactionDescription: element.TransactionDescription || '', 
            TransactionAmount: element.TransactionAmount,
            Comments: element.Comments || '',
          }
          transactions.push(newElement);
        }
        console.log('still going');
        console.log('______________________________');

      });
      console.log('______________________________');
      console.log(transactions);
      filteredData(transactions);
      
    } 
    
    // else if (amountOption === 'less than') {
      
    // } else{

    // }
    
    













    // console.log('-----------------------------------');
    // console.log(hasAccountSource);
    // console.log(hasStart);
    // console.log(hasEnd);
    // console.log(hasAmount);
    // console.log(hasComment);
    // console.log(hasTransDescription);
    // console.log(inputs.transDescription);

    // transColl.getDocs().then((snapshot) =>{
    //   const filteredData = snapshot.docs
    //   .filter((doc) => {
    //     const user = doc.data();
    //     const nameContainsJ = user.TransactionDescription.toLowerCase().includes('r');
    //     const cityContainsN = user.AccountSouce.toLowerCase().includes('b');
    //     return nameContainsJ && cityContainsN;
    //   })
    //   .map((doc) => doc.data());

    //   // Process the filteredData as desired
    //   console.log(filteredData);
    // })

    // const response = await fetch('./api', {
    //   method: 'POST',
    //   body: JSON.stringify({hasAmount: hasAmount, amount: inputs.amount, amountOption: amountOption,
    //                         hasComment: hasComment, comment: inputs.comment, 
    //                         hasAccountSource: hasAccountSource, accountSource: inputs.accountSource,
    //                         hasStart: hasStart, startDate: startDate,
    //                         hasEnd: hasEnd, endDate: endDate,
    //                         hasTransDescription: hasTransDescription, transDescription: inputs.transDescription,
    //                         type: 'filter'}),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   }
    // });
    // const recieved = await response.json();
    // console.log('got it');
    // console.log(recieved.message);
    // filteredData(recieved.message);
  }

  return (
    <div className="big-container" style={{ display: "flex"}}>

      <div className="accountSource-container">
        <input type="text" name="accountSource" value={inputs.accountSource || ""} onChange={handleChange} placeholder="Account Souce contains"/>
      </div>

      <div className="spaceMaker"></div>

      <div className="date-picker-container" style={{ display: "flex", flexDirection: "column", boxSizing: "border-box",}}>
        <DatePicker
          selected={startDate}
          onChange={(date) => startDate = date}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          placeholderText="Start Date"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => endDate = date}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          placeholderText="End Date"
        />
      </div>

      <div className="spaceMaker"></div>

      <div className="accountSource-container">
        <input type="text" name="transDescription" value={inputs.transDescription || ""} onChange={handleChange} placeholder="Trans Description contains"/>
      </div>

      <div className="spaceMaker"></div>

      <div className="amount-container" style={{ display: "flex", flexDirection: "column", boxSizing: "border-box",}}>
        <input type="text" id="amountFilter" name="amount" value={inputs.amount || ""} onChange={handleChange} placeholder="Transaction Amount"/>
        <div className="radios">
          <input type="radio" value="greater than" name="amount" onChange={amountRadioChange} checked={amountOption==='greater than'}/> greater than
          <input type="radio" value="less than" name="amount" onChange={amountRadioChange} checked={amountOption==='less than'}/> less than
          <input type="radio" value="equal to" name="amount" onChange={amountRadioChange} checked={amountOption==='equal to'}/> equal to
        </div>
      </div>

      <div className="spaceMaker"></div>

      <div className="comment-container">
        <input type="text" name="comment" value={inputs.comment || ""} onChange={handleChange} placeholder="comment contains"/>
      </div>

      <button onClick={applyFilters}>Apply Filters</button>

    </div>
    
  );
}

export default Filters;