import { AiFillDelete } from "react-icons/ai"


// AiFillDelete

const Table = ({ data, onDelete, user }) => {

  const parseComments = (comment)=>{
    if (comment === "null") {
      return ""
    }

    let links = '';
    let comments = '';

    const commentArr = comment.split(' ');
    for (let index = 0; index < commentArr.length; index++) {
      const element = commentArr[index];

      if (element.slice(0, 8) === 'https://' || element.slice(0,7) === 'http://') {
        links += element;
      } else{
        comments += element;
      }
    }

    if (links === '') {
      return comment;
    }
    

    return (
      <div className="extendWide">
        <a href={links} target="_blank" rel="noopener noreferrer">{links}</a>
      </div>

      // onDelete(item.TransactionID)
      
    )
  }

  const dummy = (item) =>{
    console.log(item);
  }

  return (

    <div>
        <div style={{ overflowX: 'auto' }} className="table-container">
        <table style={{ borderCollapse: 'collapse', minWidth: '100%' }}>
          <thead style={{ position: 'sticky', top: 0 }} className="fixed-header">
            <tr>
              <th style={tableHeaderStyle}>TransactionID</th>
              <th style={tableHeaderStyle}>AccountSource</th>
              <th style={tableHeaderStyle}>TransactionDate</th>

              <th style={tableHeaderStyle}>TransactionDescription</th>
              <th style={tableHeaderStyle}>TransactionAmount</th>
              {/* <th style={tableHeaderStyle}>AccountBalance</th> */}

              <th style={tableHeaderStyle}>Comments</th>
              {/* <th style={tableHeaderStyle}>InsertDate</th>
              <th style={tableHeaderStyle}>LastModifyDate</th> */}
              
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td style={tableCellStyle}> {user==2? <div> {index+1} <AiFillDelete onClick={() => onDelete(item.TransactionId)}></AiFillDelete></div> : <div>{index+1}</div>}</td>
                <td style={tableCellStyle}>{item.AccountSource}</td>
                <td style={tableCellStyle}>{item.transDate}</td>

                <td style={tableCellStyle}>{item.TransactionDescription}</td>
                <td style={tableCellStyle}>${item.TransactionAmount}</td>
                {/* <td style={tableCellStyle}>${item.AccountBalance}</td> */}

                <td style={tableCellStyle}>{parseComments(item.Comments)}</td>
                {/* <td style={tableCellStyle}>{item.insDate}</td>
                <td style={tableCellStyle}>{item.lastModDate}</td> */}
                {/* item.Comments === "null"? "": item.Comments */}
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    
  );
};

const tableHeaderStyle = {
  backgroundColor: '#000000',
  borderBottom: '1px solid #ddd',
  fontWeight: 'bold',
  padding: '8px',
  textAlign: 'left',
};

const tableCellStyle = {
  borderBottom: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
};




export default Table;