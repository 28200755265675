import { useState } from "react";
import SqlTable from './SqlTable';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const SignIn = () => {
    const [inputs, setInputs] = useState({});
    const [userlevel, setUserlevel] = useState(0);

    const verifyUser = async() =>{
        const firebaseConfig = {
        apiKey: "AIzaSyDVhgpIqkEexS6B3slFq11Y59ojOOgqj7Q",
        authDomain: "web-trans-3971a.firebaseapp.com",
        projectId: "web-trans-3971a",
        storageBucket: "web-trans-3971a.appspot.com",
        messagingSenderId: "308026154470",
        appId: "1:308026154470:web:360585ee132965749d8a27",
        measurementId: "G-ZG8VB6D2SY"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);

        const q = query(collection(db, "Users"));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            let element = doc.data();
            
            if (inputs.username === element.Email && inputs.password === element.Password) {
                console.log('here');
                
                if (element.Type === 'Manager') {
                    setUserlevel(2);
                } else {
                    setUserlevel(1);
                }
            }
        });
    }

    const logOut = ()=>{
        console.log(inputs);
        setInputs(values => ({...values, ['username']: ''}));
        setInputs(values => ({...values, ['password']: ''}));
        setUserlevel(0);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
        console.log('hi');
        verifyUser();
    }
    return ( 
        <div>
            <p>
                Staff IT Solution Inc: Accounts Recieveable Ledger
            </p>
             <p>
                Enter your username and password to access this portal
            </p>
            <form id="form1" onSubmit={handleSubmit}>
                <div >
                <label>Enter the Usernames:
                <span id="tpl"></span>  
                <input 
                    className='HTML'
                    id='HTML1'
                    type="text" 
                    name="username" 
                    value={inputs.username || ""} 
                    onChange={handleChange}
                />
                </label>
                </div>

                <div >
                <label>Enter the Password:
                <span id="tpl"></span>  
                <input 
                    className='HTML'
                    id='HTML1'
                    type="password" 
                    name="password" 
                    value={inputs.password || ""} 
                    onChange={handleChange}
                />
                </label>
                </div>

                <div id='subAndLog'>
                    <button type="submit" className='formSubmit'>Submit</button>
                    {userlevel > 0?
                    <div>
                        <button id='logOffBtn' onClick={logOut}>Log Off</button>
                    </div> 
                    :
                    <div></div>}
                </div>

                
                
            </form>
            {userlevel > 0?
            <div>
                <SqlTable SQLTable='Transactions' user={userlevel}></SqlTable>
            </div> 
            :
            <div></div>}
            
        </div>
     );
}
 
export default SignIn;