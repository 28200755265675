import { useState, useEffect } from "react";
import Table from "./Table";
import Filters from "./Filters";
import Insert from './Insert';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, getDocs, doc, deleteDoc } from "firebase/firestore";


const initialData = [
    
  ];

  // INSERT INTO `Transactions` (`TransactionID`, `AccountSource`, `TransactionDate`, `TransactionDescription`, `TransactionAmount`, `AccountBalance`, `Comments`, `InsertDate`, `LastModifyDate`) VALUES (NULL, 'bf', '2023-03-14', 'f', '4', '4', 'f', '2023-03-12', '2023-03-14')
  
  const SqlTable = ({ SQLTable, user }) => {
    const [data, setData] = useState(initialData);
    const [noData, setNoData] = useState(true);

    const firebaseConfig = {
      apiKey: "AIzaSyDVhgpIqkEexS6B3slFq11Y59ojOOgqj7Q",
      authDomain: "web-trans-3971a.firebaseapp.com",
      projectId: "web-trans-3971a",
      storageBucket: "web-trans-3971a.appspot.com",
      messagingSenderId: "308026154470",
      appId: "1:308026154470:web:360585ee132965749d8a27",
      measurementId: "G-ZG8VB6D2SY"
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

    const getData = async ()=>{

      const q = query(collection(db, "Transactions"));
      const querySnapshot = await getDocs(q);
      let transactions = [];
      querySnapshot.forEach((doc) => {
          let element = doc.data();
          const newElement = {
            TransactionId: element.TransactionId,
            AccountSource: element.AccountSource || '',
            transDate: element.transDate,
            TransactionDescription: element.TransactionDescription || '', 
            TransactionAmount: element.TransactionAmount,
            Comments: element.Comments || '',
          }
          transactions.push(newElement);
      });

      if (transactions.length === 0) {
        setNoData(true);
        setData([]);
      } else {
        setNoData(false);
        setData(transactions);
      }
    }

    const filteredData = (data)=>{
      setData(data);

    }

    useEffect(() => {
      if (data.length === 0) {
         getData()
      }
      console.log(data.length);
    }, [SQLTable]);
  
    const handleDelete = async (id) => {

      await deleteDoc(doc(db, 'Transactions', id.toString()));
      const newData = data.filter((item) => item.TransactionId !== id);
      setData(newData);

    //   const response = await fetch('./api', {
    //     method: 'POST',
    //     body: JSON.stringify({type: 'delete', table: SQLTable, id: id.toString()}),
    //     headers: {
    //       'Content-Type': 'application/json',
    //     }
    //   });
    //   const recieved = await response.json();
      console.log('deleted');
    };

  
    return (
      <div>
        {noData? <p> There is currently do data in the database</p> : <div></div>}
        <Filters filteredData={filteredData}/>
        <Table data={data} onDelete={handleDelete} user={user}/>
        {user > 1?
                <div>
                    <Insert getData={getData}></Insert>
                </div>
                :
                <div></div>}
      </div>
    );
  };
  
  export default SqlTable;